export const dispRegChoice = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'select',
        label: 'Dispensing Frequency',
        name: 'dispRegChoice',
        key: 'dispRegChoice',
        options: [
          {
            label: 'Every 7 days',
            value: 'Every 7 Days',
          },
          {
            label: 'Every 14 days',
            value: 'Every 14 days',
          },
          {
            label: 'Every 28 days',
            value: 'Every 28 days',
          },
          {
            label: 'Other',
            value: 'Other',
          }],
      },
    ],
  },
]
export const reasoning = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      // {
      //   type: 'text',
      //   label: 'Rationale',
      //   name: 'rationaleReasoning',
      //   key: 'rationaleReasoning',
      // },
      {
        type: 'text',
        label: 'Other Reasons',
        name: 'otherReasoning',
        key: 'otherReasoning',
      },

    ],
  },
]
export const rationale = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'select',
        label: 'The rationale/reason(s) for my assessment of the clinical or safety risks to the patient if larger quantities were dispensed, is/are: Based on the above noted impairments, the patient needs a frequently monitored drug regiment due to: ',
        name: 'rationale',
        multiple: 'true',
        key: 'rationale',
        options: [{
          id: 'rationaleOption1',
          label:
            'Medication regimen is complex and may forget to take medications or take multiple times throughout the day resulting in a DRP.',
          value: 'Medication regimen is complex and may forget to take medications or take multiple times throughout the day resulting in a DRP.',
        },
        {
          id: 'rationaleOption2',
          label: 'Frequent changes in medication. Patient unable to manage changes without risk of a DRP. ',
          value: 'Frequent changes in medication. Patient unable to manage changes without risk of a DRP. ',
        },
        {
          id: 'rationaleOption3',
          label: 'Patient at risk for adverse health outcomes. ',
          value: 'Patient at risk for adverse health outcomes. ',
        },
        {
          id: 'rationaleOption4',
          label: 'Patient loses medications. ',
          value: 'Patient loses medications. ',
        },
        {
          id: 'rationaleOption5',
          label: 'Patient has a hard time identifying medications which may result in a DRP. ',
          value: 'Patient has a hard time identifying medications which may result in a DRP. ',
        },
        {
          id: 'rationaleOption6',
          label: 'Patient Finds compliance packaging to be safer and more convenient. ',
          value: 'Patient Finds compliance packaging to be safer and more convenient. ',
        },
        {
          id: 'rationaleOption7',
          label: 'Patient is requesting blister packs to help with compliance. ',
          value: 'Patient is requesting blister packs to help with compliance. ',
        },
        {
          id: 'rationaleOption8',
          label: 'Patient needs close monitoring to ensure they are taking their medications. ',
          value: 'Patient needs close monitoring to ensure they are taking their medications. ',
        },
        {
          id: 'rationaleOption9',
          label: 'High potential for abuse of medications. ',
          value: 'High potential for abuse of medications. ',
        },
        {
          id: 'rationaleOption10',
          label: 'Patient requires assistance with medication regimen and vitamins and compliance. ',
          value: 'Patient requires assistance with medication regimen and vitamins and compliance. ',
        },
        {
          id: 'rationaleOption11',
          label: 'Client of special housing program. ',
          value: 'Client of special housing program. ',
        },
        {
          id: 'rationaleOption12',
          label: 'Unstable housing arrangement. ',
          value: 'Unstable housing arrangement. ',
        }],
      },
    ],
  },
]

export const pharmacistAssessment = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'checkbox',
        label: 'Physical Impairement',
        name: 'physCheck1',
        key: 'physCheck1',
        options: {
          physCheck1: 'Physical functioning',
          physCheck2: 'Mobility',
          physCheck3: 'Dexterity',
          physCheck4: 'Stamina',
          physCheck5: 'Other physical disabilities include impairments which limit other facets of daily living such as: respiratory disorders, blindness, epilepsy, sleep disorders',
        },
      },

      {
        type: 'checkbox',
        label: 'Complex Medication Regimen',
        name: 'regimenCheck1',
        key: 'regimenCheck1',
        options: {
          regimenCheck1: '1-2 = simple regimen (frequent dispensing rarely needed)',
          regimenCheck2: '3-4 = mildly complex',
          regimenCheck3: '5-6 = complex',
          regimenCheck4: '7+ = highly complex',
          regimenCheck5: 'Medications that require cutting',
          regimenCheck6: 'Medications requiring multiple doses per day',
          regimenCheck7: 'Medications on specialized schedules (once a week/everyother day, etc)',
        },
      },
      {
        type: 'checkbox',
        label: 'Sensory impairment',
        name: 'sensoryCheck1',
        key: 'sensoryCheck1',
        options: {
          sensoryCheck1: 'Sight',
          sensoryCheck2: 'Hearing',
          sensoryCheck3: 'Smell',
          sensoryCheck4: 'Touch',
          sensoryCheck5: 'Taste',
          sensoryCheck6: 'Spatial Awareness',
        },
      },
      {
        type: 'checkbox',
        label: 'Cognitive Impairment',
        name: 'cogCheck1',
        key: 'cogCheck1',
        options: {
          cogCheck1: 'Remembering',
          cogCheck2: 'Learning new things',
          cogCheck3: 'Concentrating',
          cogCheck4: 'Making decisions that affect their everyday life ',
          cogCheck5: 'Drug-induced impairment in cognition and memory',
        },
      },
    ],
  },
]
export const patientInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Patient First Name',
        name: 'patientfirstname',
        key: 'patientfirstname',
      },
      {
        type: 'text',
        label: 'Patient Last Name',
        name: 'patientlastname',
        key: 'patientlastname',
      },
      {
        type: 'text',
        label: 'Patient Date of Birth',
        name: 'dob',
        key: 'dob',
      },
    ],
  },
]
export const doctorInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Doctor First Name',
        name: 'drfirstname',
        key: 'drfirstname',
      },
      {
        type: 'text',
        label: 'Doctor Last Name',
        name: 'drlastname',
        key: 'drlastname',
      },
      {
        type: 'text',
        label: 'Doctor Fax',
        name: 'drfax',
        key: 'drfax',
      },
      {
        type: 'text',
        label: 'Doctor Tel',
        name: 'drtel',
        key: 'drtel',
      },
      {
        type: 'text',
        label: 'CPSO',
        name: 'cpso',
        key: 'cpso',
      },
      {
        type: 'text',
        label: 'Doctor Notification Date',
        name: 'drNotificationDate',
        key: 'drNotificationDate',
      },
    ],
  },
]
export const pharmacyInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacy Name',
        name: 'pharmacyName',
        key: 'pharmacyName',
      },
      {
        type: 'text',
        label: 'Pharmacy Tel Number',
        name: 'pharmacyTelephone',
        key: 'pharmacyTelephone',
      },
      {
        type: 'text',
        label: 'Pharmacy Fax Number',
        name: 'pharmacyFax',
        key: 'pharmacyFax',
      },
      {
        type: 'text',
        label: 'Pharmacy Address',
        name: 'pharmacyFullAdd',
        key: 'pharmacyFullAdd',
      },

    ],
  },
]

export const pharmacistinfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacist First Name',
        name: 'rphfirstname',
        key: 'rphfirstname',
      },
      {
        type: 'text',
        label: 'Pharmacist Last Name',
        name: 'rphlastname',
        key: 'rphlastname',
      },
      {
        type: 'text',
        label: 'OCP#',
        name: 'ocp',
        key: 'ocp',
      },

      // {
      //   type: 'text',
      //   label: 'Rx Number',
      //   name: 'rxNumber',
      //   key: 'rxNumber',
      // },
    ],
  },
]
